// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-event-js": () => import("./../../../src/components/event.js" /* webpackChunkName: "component---src-components-event-js" */),
  "component---src-components-historical-figure-js": () => import("./../../../src/components/historical-figure.js" /* webpackChunkName: "component---src-components-historical-figure-js" */),
  "component---src-components-historical-site-js": () => import("./../../../src/components/historical-site.js" /* webpackChunkName: "component---src-components-historical-site-js" */),
  "component---src-components-interview-js": () => import("./../../../src/components/interview.js" /* webpackChunkName: "component---src-components-interview-js" */),
  "component---src-components-tag-js": () => import("./../../../src/components/tag.js" /* webpackChunkName: "component---src-components-tag-js" */),
  "component---src-components-theme-js": () => import("./../../../src/components/theme.js" /* webpackChunkName: "component---src-components-theme-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bibliography-js": () => import("./../../../src/pages/bibliography.js" /* webpackChunkName: "component---src-pages-bibliography-js" */),
  "component---src-pages-foo-js": () => import("./../../../src/pages/foo.js" /* webpackChunkName: "component---src-pages-foo-js" */),
  "component---src-pages-historical-figures-js": () => import("./../../../src/pages/historical-figures.js" /* webpackChunkName: "component---src-pages-historical-figures-js" */),
  "component---src-pages-historical-map-js": () => import("./../../../src/pages/historical-map.js" /* webpackChunkName: "component---src-pages-historical-map-js" */),
  "component---src-pages-historical-sites-js": () => import("./../../../src/pages/historical-sites.js" /* webpackChunkName: "component---src-pages-historical-sites-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interviews-js": () => import("./../../../src/pages/interviews.js" /* webpackChunkName: "component---src-pages-interviews-js" */),
  "component---src-pages-themes-js": () => import("./../../../src/pages/themes.js" /* webpackChunkName: "component---src-pages-themes-js" */)
}

